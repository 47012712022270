angular.module('fingerink')
    .service('selectTemplateModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (type) {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                controller: 'selectTemplateModalCtrl',
                controllerAs: 'controller',
                templateUrl: 'modals/selectTemplateModal.tpl.html',
                resolve: {
                    signatureType: function () {
                        return type;
                    }
                }
            });

            return this.modalInstance;
        };

    })
    .controller('selectTemplateModalCtrl', function selectTemplateModalCtrl($scope, $uibModalInstance, $window, $timeout, session, templateService, signatureType) {
        var that = this;

        that.signatureType = signatureType;
        that.me = session.get().user;
        that.loading = true;
        templateService.getPlantillas({
            creador: that.me.id,
            eliminado: 0
        }).then(function (response) {
            that.plantillas = response.data;
            that.loading = false;
        });


        that.close = function () {
            $uibModalInstance.close();
        };

        that.ok = function (s) {
            $uibModalInstance.close(s);
        };
    });
